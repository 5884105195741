<!-- =========================================================================================
  File Name: DashboardAnalytics.vue
  Description: Dashboard Analytics

========================================================================================== -->

<template>
  <div id="course-page">
    <vs-alert :active.sync="errorOccurred" color="danger" title="Course Not Found">
      <span>Course record with id: {{ $route.params.courseId }} not found. </span>
      <span> <span>Check </span><router-link :to="{ name: 'courses' }" class="text-inherit underline">Course Selector</router-link> </span>
    </vs-alert>

    <div class="vx-row">
      <div class="vx-col w-full mb-4">
        <div class="vx-card rounded-lg flex w-full p-4">
          <div class="flex justify-between w-full">
            <div class="flex flex-wrap content-center pl-2">
              <h1 class="font-bold text-white w-full" style="font-size: 2.5rem" v-if="courseData">{{ courseData.name }}</h1>
              <h2 class="mb-2 text-white font-light w-full" v-if="courseData">Mass Course Report</h2>
            </div>
            <div class="flex w-full justify-end gap-x-4">
              <img
                :src="
                  courseData.thumbnail !== null
                    ? courseData.thumbnail
                    : 'https://connect-cdn.intellectualpoint.com/assets/images/misc/default-placeholder.png'
                "
                class="rounded hidden lg:block"
                style="max-width: 100px; max-height: 100px; width: 20%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="vx-row" v-if="!courseCompletionData">
      <div class="vx-col w-full">
        <vx-card>
          <h4 class="font-light mb-2">Enter Emails To Generate Report</h4>
          <vs-textarea height="600px" v-model="massEmails" class="mb-4" />
          <vs-button type="gradient" @click="generateReport()">Generate Report</vs-button>
        </vx-card>
      </div>
    </div>

    <div class="vx-row" v-if="courseCompletionData" id="CourseViewMassProgress">
      <div class="vx-col w-full">
        <vs-table :data="courseCompletionData.users" max-items="10" search class="main-border rounded-lg" pagination>
          <template slot="header">
            <h3 class="ml-4 mr-6">Generated Report - {{ courseCompletionData.users.length }} Students</h3>
            <vs-button class="w-fit font-bold" size="small" icon="icon-download" icon-pack="feather" color="primary" @click="exportData"
              >Export Data
            </vs-button>
          </template>

          <template slot="thead">
            <vs-th>Student</vs-th>
            <vs-th>Email</vs-th>
            <vs-th>Final Grade</vs-th>
            <vs-th>Final Percentage</vs-th>
            <vs-th>ExamEdge Grade</vs-th>
            <vs-th>ExamEdge Assignments</vs-th>
            <vs-th>Simulations Grade</vs-th>
            <vs-th>FlashCards Grade</vs-th>
            <vs-th>Labs Grade</vs-th>
            <!-- <vs-th>View More Details</vs-th> -->
          </template>

          <template slot-scope="{ data }">
            <vs-tr v-for="attach in data" :key="attach.id" class="text-lg">
              <vs-td :data="attach"> {{ attach.firstName }} {{ attach.lastName }} </vs-td>

              <vs-td :data="attach.email">
                {{ attach.email }}
              </vs-td>

              <vs-td
                v-if="!attach.progress.final.error"
                :data="attach.progress.final"
                class="font-bold"
                style="font-size: 1.3rem"
                :class="getGradeClass(attach.progress.final.grade)"
              >
                {{ attach.progress.final.grade }}
              </vs-td>

              <vs-td v-else>N/A</vs-td>

              <vs-td
                v-if="!attach.progress.final.error"
                :data="attach.progress.final"
                class="font-bold"
                style="font-size: 1.3rem"
                :class="getGradeClass(attach.progress.final.grade)"
              >
                {{ attach.progress.final.percent }}%
              </vs-td>

              <vs-td v-else>N/A</vs-td>

              <vs-td :data="attach.progress.examedge">
                <span v-if="attach.progress.examedge.enabled"
                  >{{ attach.progress.examedge.totalGrade }} ({{ attach.progress.examedge.totalGradePercent }}%)</span
                >
                <span v-else>N/A</span>
              </vs-td>

              <vs-td :data="attach.progress.examedge">
                <span v-if="attach.progress.examedge.enabled"
                  >{{ attach.progress.examedge.passedCount }} / {{ attach.progress.examedge.total }} Complete</span
                >
                <span v-else>N/A</span>
              </vs-td>

              <vs-td :data="attach.progress.simulations">
                <span v-if="attach.progress.simulations.enabled"
                  >{{ attach.progress.simulations.totalGrade }} ({{ attach.progress.simulations.totalGradePercent }}%)</span
                >
                <span v-else>N/A</span>
              </vs-td>

              <vs-td :data="attach.progress.flashcards">
                <span v-if="attach.progress.flashcards.enabled"
                  >{{ attach.progress.flashcards.totalGrade }} ({{ attach.progress.flashcards.totalGradePercent }}%)</span
                >
                <span v-else>N/A</span>
              </vs-td>

              <vs-td :data="attach.progress.labs">
                <span v-if="attach.progress.labs.enabled">
                  {{ attach.progress.labs.totalGrade }} ({{ attach.progress.labs.totalGradePercent }}%)</span
                >
                <span v-else>N/A</span>
              </vs-td>

              <!-- <vs-td :data="attach.progress.examedge">
                <vs-button
                  color="success"
                  size="small"
                  type="border"
                  icon-pack="feather"
                  icon="icon-file-text"
                  class="w-full"
                  @click="openProgress(attach.id)"
                >
                  View Details
                </vs-button>
              </vs-td> -->
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import LottieAnimation from '../../../../../components/lottie/LottieAnimation';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { P } from '@bachdgvn/vue-otp-input';

export default {
  data() {
    return {
      courseData: null,
      errorOccurred: false,

      massEmails: '',
      courseCompletionData: null,
      courseCompletionTotal: null,
      totalSetsPercentage: null,

      extractedExportData: {},
    };
  },
  components: {
    VueApexCharts,
    LottieAnimation,
  },
  computed: {
    activeCourseInfo() {
      return this.$store.state.AppActiveCourse;
    },
  },
  methods: {
    loadCourseInfo(id) {
      this.$http
        .get(`course/${id}`)
        .then((response) => {
          if (response.data) {
            this.courseData = response.data;
          }
        })
        .catch(() => {});
    },
    openProgress(uid) {
      window.open(`/a/users/${uid}/progress/${this.courseData.id}`, '_blank');
    },
    generateReport() {
      this.$vs.loading();
      const emailsArray = this.massEmails
        .split('\n')
        .map((email) => email.trim())
        .filter((email) => email !== '');

      this.$http
        .post('compliance/report/mass', { courseId: this.courseData.id, emails: emailsArray })
        .then((response) => {
          if (response.data) {
            this.$vs.loading.close();
            this.courseCompletionTotal = response.data;
            this.courseCompletionData = this.courseCompletionTotal;
            this.courseCompletionData.users.sort((a, b) => b.progress.final.percent - a.progress.final.percent);
          }
        })
        .catch(() => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: 'Report Could Not Be Generated',
            text: 'Please email cameron@intellectualpoint.com for more information.',
            color: 'danger',
            position: 'top-right',
          });
        });
    },
    async exportData() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Report', { properties: { tabColor: { argb: 'FFC0000' } } });

      const dateTimeGenerated = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true, // Use 12-hour clock
      })
        .format(new Date())
        .replaceAll(',', '');

      const formattedDate = dateTimeGenerated.replaceAll('/', '-').replaceAll(':', ' ');

      // Define colors for grades with alternating shades
      const gradeColors = {
        'A+': { light: 'FFC6EFCE', dark: 'FFB7DEAC' }, // Lighter green to darker green
        A: { light: 'FFC6EFCE', dark: 'FFB7DEAC' },
        'A-': { light: 'FFC6EFCE', dark: 'FFB7DEAC' },
        'B+': { light: 'FF9FC5E8', dark: 'FF8FB0D9' },
        B: { light: 'FF9FC5E8', dark: 'FF8FB0D9' },
        'B-': { light: 'FF9FC5E8', dark: 'FF8FB0D9' },
        'C+': { light: 'FFF9CB9C', dark: 'FFE8B989' },
        C: { light: 'FFF9CB9C', dark: 'FFE8B989' },
        'C-': { light: 'FFF9CB9C', dark: 'FFE8B989' },
        'D+': { light: 'FFFCBABA', dark: 'FFFDCCCC' }, // Light red to slightly darker red
        D: { light: 'FFFCBABA', dark: 'FFFDCCCC' }, // Slightly darker red to medium red
        'D-': { light: 'FFFCBABA', dark: 'FFFDCCCC' }, // Medium red to deeper red
        F: { light: 'FFFF8080', dark: 'FFD9534F' }, // Deeper red to darkest red
      };

      // Initialize headers with the basic set of headers
      const headers = new Set(['First Name', 'Last Name', 'Email']);

      // Dynamically add headers based on the available data
      this.courseCompletionData.users.forEach((user) => {
        if (!user.progress.final.error) {
          headers.add('Final Grade');
          headers.add('Final Percent');
        }

        if (user.progress.examedge.enabled) {
          headers.add('ExamEdge Grade');
          headers.add('ExamEdge Percent');
          Object.keys(user.progress.examedge.assignments).forEach((_, index) => {
            headers.add(`ExamEdge Assignment ${index + 1}: Grade`);
            headers.add(`ExamEdge Assignment ${index + 1}: Percent`);
            headers.add(`ExamEdge Assignment ${index + 1}: Status`);
          });
        }

        if (user.progress.simulations.enabled) {
          headers.add('Simulation Grade');
          headers.add('Simulation Percent');
        }

        if (user.progress.flashcards.enabled) {
          headers.add('FlashCards Grade');
          headers.add('FlashCards Percent');
        }

        if (user.progress.labs.enabled) {
          headers.add('Labs Grade');
          headers.add('Labs Percent');
        }
      });

      // Convert headers set to array and map it to the columns format
      worksheet.columns = Array.from(headers).map((header) => ({ header, key: header, width: 20 }));

      // Map the data
      const mappedData = this.courseCompletionData.users.map((user) => {
        let userData = {
          'First Name': user.firstName,
          'Last Name': user.lastName,
          Email: user.email,
        };

        // Add final
        if (!user.progress.final.error) {
          userData['Final Grade'] = user.progress.final.grade;
          userData['Final Percent'] = user.progress.final.percent + '%';
        }

        // Conditionally add enabled features to userData with checks for data existence
        if (user.progress.examedge.enabled) {
          userData['ExamEdge Grade'] = user.progress.examedge.totalGrade;
          userData['ExamEdge Percent'] = user.progress.examedge.totalGradePercent + '%';
          Object.keys(user.progress.examedge.assignments).forEach((assignmentName, index) => {
            const assignment = user.progress.examedge.assignments[assignmentName];
            const assignmentPrefix = `ExamEdge Assignment ${index + 1}`;
            userData[`${assignmentPrefix}: Grade`] = assignment.bestGrade;
            userData[`${assignmentPrefix}: Percent`] = assignment.bestPercent + '%';
            userData[`${assignmentPrefix}: Status`] = assignment.passed ? 'Passed' : 'Failed';
          });
        }

        // Simulations
        if (user.progress.simulations.enabled) {
          userData['Simulation Grade'] = user.progress.simulations.totalGrade;
          userData['Simulation Percent'] = user.progress.simulations.totalGradePercent + '%';
        }

        // FlashCards
        if (user.progress.flashcards.enabled) {
          userData['FlashCards Grade'] = user.progress.flashcards.totalGrade;
          userData['FlashCards Percent'] = user.progress.flashcards.totalGradePercent + '%';
        }

        // Labs
        if (user.progress.labs.enabled) {
          userData['Labs Grade'] = user.progress.labs.totalGrade;
          userData['Labs Percent'] = user.progress.labs.totalGradePercent + '%';
        }

        return userData;
      });

      // Highlight the header row
      const headerRow = worksheet.getRow(1); // Get the header row
      headerRow.eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '0152FB' }, // Background color
          bgColor: { argb: '0152FB' },
        };
        cell.font = {
          color: { argb: 'FFFFFF' }, // Text color
          bold: true,
        };
        cell.alignment = { horizontal: 'left' }; // Center align header text
      });

      // Add user data and apply styles
      mappedData.forEach((user, index) => {
        const userRow = worksheet.getRow(index + 2); // Plus 2 because Excel is 1-indexed and there is a header row

        // Apply alternating row colors and populate row data
        Object.keys(user).forEach((key, colIndex) => {
          // Get The Current Cell
          const cell = userRow.getCell(colIndex + 1);

          // Set The Background Row Color For Grade
          const grade = user['Final Grade'];
          const gradeColor = gradeColors[grade]
            ? index % 2 === 0
              ? gradeColors[grade].light
              : gradeColors[grade].dark
            : gradeColors['F'].light;
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: gradeColor },
          };

          // Set The Cell Value
          cell.value = user[key];

          // Align Cell To The Left
          cell.alignment = { horizontal: 'left' };

          // Bold the grade cell
          if (key === 'Final Grade') {
            cell.font = { bold: true };
          }

          // Check if Percent
          if (typeof user[key] === 'string' && user[key].endsWith('%')) {
            // Remove the '%' and convert to number
            const percentageValue = parseFloat(user[key].slice(0, -1)) / 100; // Convert to decimal
            cell.value = percentageValue;
            cell.numFmt = '0.00%'; // Apply percentage format
          } else {
            cell.value = user[key];
          }
        });
      });

      // Course Info Shit
      const headerStyle = {
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFD9D9D9' } },
        font: { color: { argb: 'FF000000' }, bold: true },
        alignment: { horizontal: 'left' },
        border: {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } },
        },
      };

      const applyStylesToRow = (row, startColumn, styles) => {
        row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
          if (colNumber >= startColumn) {
            Object.assign(cell, styles);
          }
        });
      };

      // Calculate the average percent
      const averagePercent =
        this.courseCompletionData.users.length > 0
          ? (
              this.courseCompletionData.users.reduce((acc, curr) => {
                return acc + (curr.progress.final && curr.progress.final.percent ? curr.progress.final.percent : 0);
              }, 0) / this.courseCompletionData.users.length
            ).toFixed(2)
          : 'N/A';

      // Set column for details width
      const detailsStartColumn = worksheet.columnCount + 3; // Start 2 columns after the last user data column
      worksheet.getColumn(detailsStartColumn).width = 20; // Make the column wider
      worksheet.getColumn(detailsStartColumn + 1).width = 30; // Adjust based on your needs

      // Add Course Details and Apps Weighting Information
      const courseDetails = [
        { label: 'Course Name', value: this.courseCompletionData.course.name },
        { label: 'Total Students', value: this.courseCompletionData.users.length },
        { label: 'Course Code', value: this.courseCompletionData.course.code },
        { label: 'Date Time Generated', value: dateTimeGenerated },
      ];

      // Calculate Starting Row for Course Details
      let currentRow = 2; // Starting from the second row

      courseDetails.forEach((detail) => {
        const row = worksheet.getRow(currentRow++);
        row.getCell(detailsStartColumn).value = detail.label;
        row.getCell(detailsStartColumn + 1).value = detail.value;
        applyStylesToRow(row, detailsStartColumn, headerStyle);
      });

      // Add Spacing
      currentRow++;

      // Add Header for Apps Weighting Section
      const appsWeightingHeaderRow = worksheet.getRow(currentRow++);
      appsWeightingHeaderRow.getCell(detailsStartColumn).value = 'App Name';
      appsWeightingHeaderRow.getCell(detailsStartColumn + 1).value = 'App Weighting';
      applyStylesToRow(appsWeightingHeaderRow, detailsStartColumn, headerStyle);

      Object.entries(this.courseCompletionData.course.appsWeighting).forEach((item, index) => {
        const row = worksheet.getRow(currentRow + index);
        row.getCell(detailsStartColumn).value = `${item[0].charAt(0).toUpperCase()}${item[0].slice(1)}`;
        // Ensure that `weight` is correctly accessed from `appDetails`
        row.getCell(detailsStartColumn + 1).value = `${item[1].weight}%`;
        applyStylesToRow(row, detailsStartColumn, headerStyle);
      });

      // Add Spacing
      currentRow += Object.keys(this.courseCompletionData.course.appsWeighting).length + 1;

      // Add "Date Time Generated", "Average Percent", and "Average Grade"
      const additionalDetails = [
        { label: 'Date Time Generated', value: dateTimeGenerated },
        { label: 'Average Percent', value: `${averagePercent}%` },
        { label: 'Average Grade', value: this.percentToGrade(averagePercent) },
      ];

      additionalDetails.forEach((detail) => {
        const row = worksheet.getRow(currentRow++);
        row.getCell(detailsStartColumn).value = detail.label;
        row.getCell(detailsStartColumn + 1).value = detail.value;
        applyStylesToRow(row, detailsStartColumn, headerStyle);
      });

      // Write the workbook to a buffer and then save using FileSaver
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `LEARN CR ${this.courseCompletionData.course.name} @ ${formattedDate}.xlsx`);
    },
    // exportData() {
    //   const currentDate = new Date();
    //   const formattedDate = `${currentDate.getMonth() + 1}-${currentDate.getDate()}-${currentDate.getFullYear()}`;

    // const mappedData = this.courseCompletionData.users.map((user) => {
    //   let userData = {
    //     'First Name': user.firstName,
    //     'Last Name': user.lastName,
    //     Email: user.email,
    //     'Final Percent': user.progress.final.percent,
    //     'Final Grade': user.progress.final.grade,
    //   };

    //   if (user.progress.examedge.enabled) {
    //     userData['ExamEdge Grade'] = user.progress.examedge.totalGrade;
    //     userData['ExamEdge Percent'] = user.progress.examedge.totalGradePercent;

    //     // Map assignments
    //     Object.keys(user.progress.examedge.assignments).forEach((assignmentName, index) => {
    //       const assignment = user.progress.examedge.assignments[assignmentName];
    //       userData[`ExamEdge Assignment ${index + 1}: Grade`] = assignment.bestGrade;
    //       userData[`ExamEdge Assignment ${index + 1}: Percent`] = assignment.bestPercent;
    //       userData[`ExamEdge Assignment ${index + 1}: Status`] = assignment.passed ? 'Passed' : 'Failed';
    //     });
    //   }

    //   if (user.progress.simulations.enabled) {
    //     userData['Simulation Grade'] = user.progress.simulations.totalGrade;
    //     userData['Simulation Percent'] = user.progress.simulations.totalGradePercent;
    //   }

    //   if (user.progress.flashcards.enabled) {
    //     userData['FlashCards Grade'] = user.progress.flashcards.totalGrade;
    //     userData['FlashCards Percent'] = user.progress.flashcards.totalGradePercent;
    //   }

    //   if (user.progress.labs.enabled) {
    //     userData['Labs Grade'] = user.progress.labs.totalGrade;
    //     userData['Labs Percent'] = user.progress.labs.totalGradePercent;
    //   }

    //   return userData;
    // });

    //   const excelFileName = `MCR - Students.xlsx`;
    //   const data = XLSX.utils.json_to_sheet(mappedData);
    //   const wb = XLSX.utils.book_new();
    //   XLSX.utils.book_append_sheet(wb, data, `Mass Course Report - ${formattedDate}`);
    //   XLSX.writeFile(wb, excelFileName);
    // },
    percentToGrade(percent) {
      if (percent >= 97) return 'A+';
      else if (percent >= 93) return 'A';
      else if (percent >= 90) return 'A-';
      else if (percent >= 87) return 'B+';
      else if (percent >= 83) return 'B';
      else if (percent >= 80) return 'B-';
      else if (percent >= 77) return 'C+';
      else if (percent >= 73) return 'C';
      else if (percent >= 70) return 'C-';
      else if (percent >= 67) return 'D+';
      else if (percent >= 63) return 'D';
      else if (percent >= 60) return 'D-';
      else return 'F';
    },
    getGradeClass(letter) {
      if (['A+', 'A', 'A-'].includes(letter)) return 'text-success';
      if (['B+', 'B', 'B-'].includes(letter)) return 'text-warning';
      if (['C+', 'C', 'C-'].includes(letter)) return 'text-warning';
      if (['D+', 'D', 'D-'].includes(letter)) return 'text-danger';
      if (letter === 'F') return 'text-danger';
      return '';
    },
  },
  beforeMount() {
    this.loadCourseInfo(this.$route.params.courseId);
  },
  watch: {},
};
</script>

<style lang="scss">
#CourseViewMassProgress {
  .vs-table--header {
    border-bottom: 1px solid #181d2a;
  }

  .vs-con-table .vs-con-tbody .vs-table--tbody-table .vs-table--thead tr {
    border-bottom: 1px solid #181d2a !important;
  }

  .vs-con-table .vs-con-tbody .vs-table--tbody-table tr {
    border-bottom: 1px solid #181d2a !important;
    border-radius: 0rem !important;
  }

  .vs-pagination--nav .vs-pagination--buttons {
    background: #181d2a;
    color: #ffffff;
  }
}
</style>
